import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import jwt from "jsonwebtoken";
import { createStore, applyMiddleware, compose } from "redux";
import setAuthorizationToken from "./views/utils/setAuthorizationToken";
import { setCurrentUser } from "./views/actions/authActions";
import rootReducer from "./views/reducers/rootRecuder";
import "./App.scss";
import Dashboard from "./views/Dashboard/Dashboard";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
  store.dispatch(setCurrentUser(jwt.decode(localStorage.jwtToken)));
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    store.getState().auth.isAuthenticated
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)
class App extends Component {
  // isUserLoggedIn() {
  //   var user = store.getState().auth.isAuthenticated;

  //   return user.auth.isAuthenticated;
  // }

  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={props => <Login {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={props => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={props => <Page500 {...props} />}
              />
              <PrivateRoute path='/' name='Dashboard' component={DefaultLayout} />
              {/* <Route
                exact
                path="/dashboard"
                name="Dashboard"
                render={props => <DefaultLayout {...props} />}
              /> */}
              {/* <Route
                exact
                path="/"
                name="Dashboard"
                render={props =>
                  this.isUserLoggedIn() ? (
                    <DefaultLayout {...props} />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              /> */}
            </Switch>
          </React.Suspense>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
