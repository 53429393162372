import { combineReducers } from 'redux';

import auth from './authReducer';
import chart from './chartReducer';
import device from './deviceReducer';
import question from './questionReducer';

export default combineReducers({
    auth,
    chart,
    device,
    question
})