import React, { Component } from "react";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Progress,
  Row
} from "reactstrap";
import { CustomTooltips } from "@coreui/coreui-plugin-chartjs-custom-tooltips";
import { getStyle, hexToRgba } from "@coreui/coreui/dist/js/coreui-utilities";
import axios from "axios";
import jwt from "jsonwebtoken";
import download from "downloadjs";
import config from "../../config/config";

var base = config.baseURL;
var owner =
  localStorage.getItem("jwtToken") &&
  jwt.decode(localStorage.getItem("jwtToken")).username;

const brandInfo = getStyle("--info");
const brandSuccess = getStyle("--success");
const brandWarning = getStyle("--warning");
const brandDanger = getStyle("--danger");

// Card Chart 2
const cardChartData1 = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "",
      backgroundColor: brandInfo,
      borderColor: "rgba(255,255,255,.55)",
      data: [1, 18, 9, 17, 34, 22, 11]
    }
  ]
};

const cardChartOpts1 = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: "transparent",
          zeroLineColor: "transparent"
        },
        ticks: {
          fontSize: 2,
          fontColor: "transparent"
        }
      }
    ],
    yAxes: [
      {
        display: false,
        ticks: {
          display: false,
          min: Math.min.apply(Math, cardChartData1.datasets[0].data) - 5,
          max: Math.max.apply(Math, cardChartData1.datasets[0].data) + 5
        }
      }
    ]
  },
  elements: {
    line: {
      tension: 0.00001,
      borderWidth: 1
    },
    point: {
      radius: 4,
      hitRadius: 10,
      hoverRadius: 4
    }
  }
};

// Card Chart 2
const cardChartData2 = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "",
      backgroundColor: brandSuccess,
      borderColor: "rgba(255,255,255,.55)",
      data: [1, 18, 9, 17, 34, 22, 11]
    }
  ]
};

const cardChartOpts2 = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          color: "transparent",
          zeroLineColor: "transparent"
        },
        ticks: {
          fontSize: 2,
          fontColor: "transparent"
        }
      }
    ],
    yAxes: [
      {
        display: false,
        ticks: {
          display: false,
          min: Math.min.apply(Math, cardChartData2.datasets[0].data) - 5,
          max: Math.max.apply(Math, cardChartData2.datasets[0].data) + 5
        }
      }
    ]
  },
  elements: {
    line: {
      tension: 0.00001,
      borderWidth: 1
    },
    point: {
      radius: 4,
      hitRadius: 10,
      hoverRadius: 4
    }
  }
};

// Card Chart 3
const cardChartData3 = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "",
      backgroundColor: "rgba(255,255,255,.2)",
      borderColor: "rgba(255,255,255,.55)",
      data: [78, 81, 80, 45, 34, 12, 40]
    }
  ]
};

const cardChartOpts3 = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        display: false
      }
    ],
    yAxes: [
      {
        display: false
      }
    ]
  },
  elements: {
    line: {
      borderWidth: 2
    },
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4
    }
  }
};

// Card Chart 4
const cardChartData4 = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "",
      backgroundColor: brandDanger,
      borderColor: "rgba(255,255,255,.55)",
      data: [1, 18, 9, 17, 34, 22, 11]
    }
  ]
};

const cardChartOpts4 = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        display: false,
        barPercentage: 0.6
      }
    ],
    yAxes: [
      {
        display: false
      }
    ]
  }
};

const mainChartOpts = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips,
    intersect: true,
    mode: "index",
    position: "nearest",
    callbacks: {
      labelColor: function(tooltipItem, chart) {
        return {
          backgroundColor:
            chart.data.datasets[tooltipItem.datasetIndex].borderColor
        };
      }
    }
  },
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: false
        },
        gridLines: {
          display: false
        }
      }
    ]
  },
  elements: {
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3
    }
  }
};

const options = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false
};

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.getData = this.getData.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    this.downloadStatistics = this.downloadStatistics.bind(this);

    this.state = {
      dropdownOpen: false,
      radioSelected: 1,
      likes: 0,
      neutrals: 0,
      dislikes: 0,
      startDate: "01.01.2018",
      endDate: "02.05.2018",
      bar: {
        labels: [
          "Много доволни",
          "Доволни",
          "Неутрални",
          "Недоволни",
          "Много недоволни"
        ],
        datasets: [
          {
            label: "Гласове",
            backgroundColor: "Orange",
            borderColor: "#2f353a",
            borderWidth: 1,
            hoverBackgroundColor: "#2f353a",
            hoverBorderColor: "#2f353a",
            data: [0, 0, 0, 0, 0]
          }
        ],
        legend: {
          position: "right"
        }
      },
      pie: {
        labels: [
          "Много доволни",
          "Доволни",
          "Неутрални",
          "Недоволни",
          "Много недоволни"
        ],
        datasets: [
          {
            data: [0, 0, 0, 0, 0],
            backgroundColor: [
              "#155e0f",
              "Green",
              "Yellow",
              "Red",
              "#ba0000"
            ],
            hoverBackgroundColor: [
              "#155e0f",
              "Green",
              "Yellow",
              "Red",
              "#ba0000"
            ]
          }
        ]
      },
      mainChart: {
        labels: [
          "Mo",
          "Tu",
          "We",
          "Th",
          "Fr",
          "Sa",
          "Su",
          "Mo",
          "Tu",
          "We",
          "Th",
          "Fr",
          "Sa",
          "Su",
          "Mo",
          "Tu",
          "We",
          "Th",
          "Fr",
          "Sa",
          "Su",
          "Mo",
          "Tu",
          "We",
          "Th",
          "Fr",
          "Sa",
          "Su"
        ],
        datasets: [
          {
            label: "Super Likes",
            backgroundColor: hexToRgba(brandSuccess, 10),
            borderColor: "#155e0f",
            pointHoverBackgroundColor: "#fff",
            borderWidth: 2,
            data: []
          },
          {
            label: "Likes",
            backgroundColor: hexToRgba(brandSuccess, 10),
            borderColor: brandSuccess,
            pointHoverBackgroundColor: "#fff",
            borderWidth: 2,
            data: []
          },
          {
            label: "Neutrals",
            backgroundColor: "transparent",
            borderColor: brandWarning,
            pointHoverBackgroundColor: "#fff",
            borderWidth: 2,
            data: []
          },
          {
            label: "Dislikes",
            backgroundColor: "transparent",
            borderColor: brandDanger,
            pointHoverBackgroundColor: "#fff",
            borderWidth: 1,
            borderWidth: 2,
            data: []
          },
          {
            label: "Super Dislikes",
            backgroundColor: "transparent",
            borderColor: "#ba0000",
            pointHoverBackgroundColor: "#fff",
            borderWidth: 1,
            borderWidth: 2,
            data: []
          }
        ]
      }
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  onRadioBtnClick(radioSelected) {
    this.setState({
      radioSelected: radioSelected
    });
  }

  componentWillMount() {
    this.changeDate("day");
    this.getData();

    this.intervalID = setInterval(() => {
      this.getData();
    }, 2000);
  }

  downloadStatistics() {
    var fileName = 'export.xlsx';
    var mimeType = 'application/vnd.ms-excel';
    var mimeType2 = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';


    axios({
      url: `${base}/data/export/${owner}`, //your url
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', fileName); //or any other extension
       document.body.appendChild(link);
       link.click();
    });

    // axios.get(`${base}/data/export/${owner}`)
    // .then((res) => {
    //   console.log(res.data);
    //   // download(res.data);
    // }).catch((error) => {      
    //   console.log(error);
    // }); 
  }

  getData() {
    var self = this;

    axios.get(`${base}/votes/count/${owner}`).then(res => {
      self.setState({
        superlikes: res.data.superlike || 0,
        likes: res.data.like || 0,
        neutrals: res.data.neutral || 0,
        dislikes: res.data.dislike || 0,
        superdislikes: res.data.superdislike || 0
      });

      self.forceUpdate();
    });

    axios
      .get(
        `${base}/votes/linechart/start/${this.state.startDate}/end/${
          this.state.endDate
        }/owner/${owner}`
      )
      .then(res => {
        self.state.mainChart.datasets[0].data = res.data.rows.superlike;
        self.state.mainChart.datasets[1].data = res.data.rows.like;
        self.state.mainChart.datasets[2].data = res.data.rows.neutral;
        self.state.mainChart.datasets[3].data = res.data.rows.dislike;
        self.state.mainChart.datasets[4].data = res.data.rows.superdislike;
        self.state.mainChart.labels = res.data.labels;
        self.forceUpdate();
      });

    axios
      .get(
        `${base}/votes/piechart/start/${this.state.startDate}/end/${
          this.state.endDate
        }/owner/${owner}`
      )
      .then(res => {
        var dataset = [
          res.data.superlike,
          res.data.like,
          res.data.neutral,
          res.data.dislike,
          res.data.superdislike
        ];
        self.state.pie.datasets[0].data = dataset;
        self.state.bar.datasets[0].data = dataset;
      });
  }

  changeDate(period) {
    /** This is the right way */
    var newStartDate = new Date();
    var newEndDate = new Date();
    var tmp;
    switch (period) {
      case "day":
        tmp = newStartDate.getDate();
        newStartDate.setDate(tmp - 1);

        this.state.startDate = newStartDate;
        this.state.endDate = newEndDate;
        break;
      case "week":
        tmp = newStartDate.getDate();
        newStartDate.setDate(tmp - 7);

        this.state.startDate = newStartDate;
        this.state.endDate = newEndDate;
        break;
      case "month":
        tmp = newStartDate.getMonth();
        newStartDate.setMonth(tmp - 1);

        this.state.startDate = newStartDate;
        this.state.endDate = newEndDate;
        break;
      case "year":
        tmp = newStartDate.getFullYear();
        newStartDate.setFullYear(tmp - 1);

        this.state.startDate = newStartDate;
        this.state.endDate = newEndDate;
        break;
    }
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  render() {
    var totalVotes =
      this.state.superlikes +
      this.state.likes +
      this.state.neutrals +
      this.state.dislikes +
      this.state.superdislikes;
    var superlikePer = Math.round((this.state.superlikes / totalVotes) * 100);
    var likePer = Math.round((this.state.likes / totalVotes) * 100);
    var neutralPer = Math.round((this.state.neutrals / totalVotes) * 100);
    var dislikePer = Math.round((this.state.dislikes / totalVotes) * 100);
    var superdislikePer = Math.round(
      (this.state.superdislikes / totalVotes) * 100
    );

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="6" lg="4">
            <Card className="text-white bg-info">
              <CardBody className="pb-0">
                <div className="text-value">{totalVotes || 0}</div>
                <div>Общо гласове</div>
              </CardBody>
              <div className="chart-wrapper mx-3" style={{ height: "70px" }}>
                <Line
                  data={cardChartData1}
                  options={cardChartOpts1}
                  height={70}
                />
              </div>
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="4">
            <Card className="text-white bg-success">
              <CardBody className="pb-0">
                <div className="text-value">{this.state.superlikes || 0}</div>
                <div>Много Доволни</div>
              </CardBody>
              <div className="chart-wrapper mx-3" style={{ height: "70px" }}>
                <Line
                  data={cardChartData2}
                  corsOptions
                  options={cardChartOpts2}
                  height={70}
                />
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Card className="text-white bg-success">
              <CardBody className="pb-0">
                <div className="text-value">{this.state.likes || 0}</div>
                <div>Доволни</div>
              </CardBody>
              <div className="chart-wrapper" style={{ height: "70px" }}>
                <Line
                  data={cardChartData3}
                  options={cardChartOpts3}
                  height={70}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6" lg="4">
            <Card className="text-white bg-warning">
              <CardBody className="pb-0">
                <div className="text-value">{this.state.neutrals || 0}</div>
                <div>Неутрални</div>
              </CardBody>
              <div className="chart-wrapper" style={{ height: "70px" }}>
                <Line
                  data={cardChartData3}
                  options={cardChartOpts3}
                  height={70}
                />
              </div>
            </Card>
          </Col>
          <Col xs="12" sm="6" lg="4">
            <Card className="text-white bg-danger">
              <CardBody className="pb-0">
                <div className="text-value">{this.state.dislikes || 0}</div>
                <div>Недоволни</div>
              </CardBody>
              <div className="chart-wrapper" style={{ height: "70px" }}>
                <Line
                  data={cardChartData3}
                  options={cardChartOpts3}
                  height={70}
                />
              </div>
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="4">
            <Card className="text-white bg-danger">
              <CardBody className="pb-0">
                <div className="text-value">{this.state.superdislikes || 0}</div>
                <div>Много Недоволни</div>
              </CardBody>
              <div className="chart-wrapper mx-3" style={{ height: "70px" }}>
                <Line
                  data={cardChartData4}
                  options={cardChartOpts4}
                  height={70}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col sm="5">
                    <CardTitle className="mb-0">Traffic</CardTitle>
                    <div className="small text-muted">
                      {new Date().getFullYear()}
                    </div>
                  </Col>
                  <Col sm="7" className="d-none d-sm-inline-block">
                    <Button color="primary" className="float-right" onClick={this.downloadStatistics}>
                      <i className="icon-cloud-download" /> Export
                    </Button>
                    <ButtonToolbar
                      className="float-right"
                      aria-label="Toolbar with button groups"
                    >
                      <ButtonGroup className="mr-3" aria-label="First group">
                        <Button
                          color="outline-secondary"
                          onClick={() => {
                            this.onRadioBtnClick(1);
                            this.changeDate("day");
                            this.getData();
                          }}
                          active={this.state.radioSelected === 1}
                        >
                          Ден
                        </Button>
                        <Button
                          color="outline-secondary"
                          onClick={() => {
                            this.onRadioBtnClick(2);
                            this.changeDate("week");
                            this.getData();
                          }}
                          active={this.state.radioSelected === 2}
                        >
                          Седмица
                        </Button>
                        <Button
                          color="outline-secondary"
                          onClick={() => {
                            this.onRadioBtnClick(3);
                            this.changeDate("month");
                            this.getData();
                          }}
                          active={this.state.radioSelected === 3}
                        >
                          Месец
                        </Button>
                        <Button
                          color="outline-secondary"
                          onClick={() => {
                            this.onRadioBtnClick(4);
                            this.changeDate("year");
                            this.getData();
                          }}
                          active={this.state.radioSelected === 4}
                        >
                          Година
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Col>
                </Row>
                <div
                  className="chart-wrapper"
                  style={{ height: 300 + "px", marginTop: 40 + "px" }}
                >
                  <Line
                    data={this.state.mainChart}
                    options={mainChartOpts}
                    height={300}
                  />
                </div>
              </CardBody>
              <CardFooter>
                <Row className="text-center">
                  <Col sm={12} md className="mb-sm-2 mb-0">
                    <div className="text-muted">Много Доволни</div>
                    <strong>
                      {this.state.superlikes} Потребители ({superlikePer}%)
                    </strong>
                    <Progress
                      className="progress-xs mt-2"
                      color="green"
                      value={superlikePer}
                    />
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0">
                    <div className="text-muted">Доволни</div>
                    <strong>
                      {this.state.likes} Потребители ({likePer}%)
                    </strong>
                    <Progress
                      className="progress-xs mt-2"
                      color="success"
                      value={likePer}
                    />
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0">
                    <div className="text-muted">Неутрални</div>
                    <strong>
                      {this.state.neutrals} Потребители ({neutralPer}%)
                    </strong>
                    <Progress
                      className="progress-xs mt-2"
                      color="warning"
                      value={neutralPer}
                    />
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0">
                    <div className="text-muted">Недоволни</div>
                    <strong>
                      {this.state.dislikes} Потребители ({dislikePer}%)
                    </strong>
                    <Progress
                      className="progress-xs mt-2"
                      color="danger"
                      value={dislikePer}
                    />
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0">
                    <div className="text-muted">Много Недоволни</div>
                    <strong>
                      {this.state.superdislikes} Потребители ({superdislikePer}
                      %)
                    </strong>
                    <Progress
                      className="progress-xs mt-2"
                      color="danger"
                      value={superdislikePer}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6" lg="6">
            <Card>
              <CardBody>
                <div className="chart-wrapper">
                  <Bar data={this.state.bar} options={options} />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="6" lg="6">
            <Card>
              <CardBody>
                <div className="chart-wrapper">
                  <Pie data={this.state.pie} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
