import axios from "axios";
import jwt from "jsonwebtoken";
import jwtDecode from "jwt-decode";
import { SET_CURRENT_USER } from "./types";
import setAuthorizationToken from "../utils/setAuthorizationToken";
import config from "../../config/config";

var base = config.baseURL;

export function logout() {
  return dispatch => {
    localStorage.removeItem("jwtToken");
    setAuthorizationToken(false);
    window.location.href = "/";
    dispatch(setCurrentUser({}));
  };
}

export function login(data) {
  return dispatch => {
    return axios.post(`${base}/authenticate`, data).then(res => {
      const token = res.data.token;

      if (token) {
        localStorage.setItem("jwtToken", token);
        setAuthorizationToken(token);
        dispatch(setCurrentUser(jwtDecode(token)));
      }
    });
  };
}

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user
  };
}
